<template>
    <div id="container" v-if="labelsInformation.length !== 0"  style="width: 100%;height:265px;">
        <canvas :id="'BarGroupTwoId'+tabIndex"></canvas>
    </div>
</template>

<script>
    import moment from 'moment';
    import { Chart, registerables } from 'chart.js';
    import { HierarchicalScale } from 'chartjs-plugin-hierarchical';
    import { mapState } from 'vuex';
    Chart.register(...registerables)
    Chart.register(HierarchicalScale);
    function data() {
        return {
            CanvasGrafict: null,
        }
    }
    function labelsInformation(){
        let data = [];
        let DataProductivity = this.$store.getters["situacionOperativaActual/myDataProductivityByTurn"];
        DataProductivity.map(async(item) => {
            let valueOne = item.TotalMovs;
            let labelDate = item.DateTransaction;
            let childreOne = [];
            item.MovsTurnJson.map(async(item2) => {
                let valuelTwo = item2.TotalMovs;
                let childrenLabelOne = item2.HourRange + ' ' + item2.TurnName;
                let childrenTwo = [];
                item2.CraneMovsJson.map(async(item3) => {
                    childrenTwo.push(item3.CraneAlias)
                })
                if(valuelTwo === 0){
                    childreOne.push(childrenLabelOne)
                }else{
                    childreOne.push({
                        label: childrenLabelOne,
                        expand: true, // 'focus', // expand level
                        children: childrenTwo
                    })
                }
            });
            if(valueOne === 0){
                data.push(labelDate);
            }else{
                data.push({
                    label: labelDate,
                    expand: true, // 'focus', // expand level
                    children: childreOne
                })
            }
        })
        return data;
    }
    function dateCranes(){
        let data = [];
        let DataProductivity = this.$store.getters["situacionOperativaActual/myDataProductivityByTurn"];
        DataProductivity.map(async(item) => {
            let valueOne = item.TotalMovs;
            let childreOne = [];
            item.MovsTurnJson.map(async(item2) => {
                let valuelTwo = item2.TotalMovs;
                let childrenTwo = [];
                item2.CraneMovsJson.map(async(item3) => {
                    childrenTwo.push(item3.Movs)
                })
                if(valuelTwo === 0){
                    childreOne.push(valuelTwo)
                }else{
                    childreOne.push({
                        value: valuelTwo,
                        children: childrenTwo
                    })
                }
            })
            if(valueOne === 0){
                data.push(valueOne);
            }else{
                data.push({
                    value: valueOne,
                    children: childreOne
                })
            }
        })
        return data;
    }
    function dateInformation(){
        let labels = this.labelsInformation;
        var densityData = [{
            label: "Gruas",
            tree: this.dateCranes
        }];
        
        const planetChartData = {
            type: 'bar',
            data: {
                labels: labels,
                datasets: densityData
            },
            options:{
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: 'Chart.js Hierarchical Bar Chart',
                },
                layout: {
                    padding: {
                        // add more space at the bottom for the hierarchy
                        bottom: 60,
                    },
                },
                scales: {
                    x: {
                        type: 'hierarchical',
                    },
                },
            },
        };
        return planetChartData;
    }
    export default {
        name:"BarGroupTwo",
        data,
        computed:{
            dateCranes,
            labelsInformation,
            dateInformation,
            ...mapState({
                tabIndex : state=> state.situacionOperativaActual.tabIndex,
            })
        },
        mounted(){
            if(this.dateCranes.length !== 0 && this.labelsInformation.length !== 0){
                let grapharea = document.getElementById('BarGroupTwoId'+this.tabIndex).getContext("2d");
                this.CanvasGrafict = new Chart(grapharea, this.dateInformation);
                this.CanvasGrafict.destroy();
            }
        },
        watch:{
            dateInformation(newValue,OldValue){
                if(this.dateCranes.length !== 0 && this.labelsInformation.length !== 0){
                    if (this.CanvasGrafict) {
                        this.CanvasGrafict.destroy();
                    }
                    let grapharea = document.getElementById('BarGroupTwoId'+this.tabIndex).getContext("2d");
                    this.CanvasGrafict = new Chart(grapharea, newValue);
                    this.CanvasGrafict.destroy();
                }
            }
        }
    }
</script>